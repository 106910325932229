@import '_main_up.scss';

body {
	font-family: 'Roboto Slab', serif;
	font-family: 'Roboto Condensed', sans-serif;
	font-family: 'Roboto', sans-serif;

}

@font-face {
	font-family: 'slick';
	src: url('../fonts/slick.eot'); /* IE9 Compat Modes */
	src: url('../fonts/slick.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/slick.woff') format('woff'), /* Pretty Modern Browsers */
	url('../fonts/slick.ttf')  format('truetype'), /* Safari, Android, iOS */
	url('../fonts/slick.svg#svgFontName') format('svg'); /* Legacy iOS */
   }

*:not(.slick-track):not(.slick-list):not(.nav__sub-list):not(.back-pulse):not(.cloud-zoom-loading) {
	transition: 0.2s ease-out;
}



.tooltip_templates { display: none; }

.tooltipster-sidetip.tooltipster-noir.tooltipster-noir-sidebar_left .tooltipster-box {
	border: 2px solid #000;
	border-radius: 50%;
	box-shadow: 5px 3px 6px -1px rgba(0,0,0,0.4);
	overflow: hidden;
	.tooltipster-arrow-background {
		top: 0;
	}
	
}
.tooltipster-sidetip.tooltipster-noir.tooltipster-noir-sidebar_left .tooltipster-content {
	
	padding: 6px 8px;
	
}

.header_xs {
	display: none;
	width: 100%;
	max-height: 255px;
	padding-top: 20px;
	margin-bottom: 106px;
	background: rgba(116,155,118,0.7);
	background: -moz-linear-gradient(top, rgba(116,155,118,0.7) 0%, rgba(255,255,255,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(116,155,118,1)), color-stop(100%, rgba(255,255,255,1)));
	background: -webkit-linear-gradient(top, rgba(116,155,118,0.7) 0%, rgba(255,255,255,1) 100%);
	background: -o-linear-gradient(top, rgba(116,155,118,0.7) 0%, rgba(255,255,255,1) 100%);
	background: -ms-linear-gradient(top, rgba(116,155,118,0.7) 0%, rgba(255,255,255,1) 100%);
	background: linear-gradient(to bottom, rgba(116,155,118,0.7) 0%, rgba(255,255,255,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#749b76', endColorstr='#ffffff', GradientType=0 ); 

}
.headline__left {
	display: none;
}
.header_xs {
	.telephon1{
		display: none;
	}
	.telephon2{
		display: none;
	}
}

header {
	width: 100%;
	max-height: 200px;
	padding-top: 2rem;
	margin-bottom: 106px;
	background: rgba(116,155,118,0.7);
	background: -moz-linear-gradient(top, rgba(116,155,118,0.7) 0%, rgba(255,255,255,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(116,155,118,1)), color-stop(100%, rgba(255,255,255,1)));
	background: -webkit-linear-gradient(top, rgba(116,155,118,0.7) 0%, rgba(255,255,255,1) 100%);
	background: -o-linear-gradient(top, rgba(116,155,118,0.7) 0%, rgba(255,255,255,1) 100%);
	background: -ms-linear-gradient(top, rgba(116,155,118,0.7) 0%, rgba(255,255,255,1) 100%);
	background: linear-gradient(to bottom, rgba(116,155,118,0.7) 0%, rgba(255,255,255,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#749b76', endColorstr='#ffffff', GradientType=0 ); 
}

.logo {
	font-weight: 300; 
	font-size: 1.45rem;
	text-align: center;
	position: relative;
	margin-top: 15px;
	div {
		max-width: 188px;
		img {
			width: 100%;
		}
	}
	span {
		font-weight: 400;
		font-size:3.9rem;
		position: absolute;
	}
	& span:first-of-type {
		top: -4.75px;
		left: 171.5px;
		transform: rotate(7deg) /*!important*/;
		/*transition: 1s ease-out;*/

	}
	& span:last-of-type {
		top: 10.25px;
		left: 189.5px;
		transform: rotate(20deg) /*!important*/;
		/*transition: 1s ease-out;*/
	}
	p {
		margin-top: 0.5rem;
	}
}
.logo span:nth-child(3) {
	animation-delay: 3s ;
	
}
.logo span:nth-child(2) {
	animation-delay: 2s ;

}
span.wow {
	visibility: hidden;
}
.gorod {
	margin-top: 15px;
}

.headline {
	text-align: center;
	h1 {
		margin-top: 0px;
		margin-bottom: 1px;
		text-transform: uppercase;
		font-size: 3rem;
		color: $orang;
		font-family: 'Roboto Slab', serif;
		font-weight: 700;

		span {
			font-size: 4rem;
			color: #000000;
			font-weight: 400;
		}
	}
}

.tagline {
	color: #fff;
	background-color: #000;
	margin: 3px 30px 0px 30px;
	font-size: 1.3rem;
	line-height: 1.3rem;
	padding-bottom: 0.1rem;
	letter-spacing: 0.2rem;
}

.telephon1 {
	float: left;
	display: block;
	font-size: 1.6rem;
	margin-top: 0.9rem;
	margin-left: 50px;

}
.telephon2 {
	display: table;
	font-size: 1.6rem;
	margin-top: 0.9rem;
	/*width: 208px;*/
	margin-right: 51px;


}
.telephon3 {
	float: left;
	display: block;
	font-size: 1.6rem;
	margin-top: 0.9rem;
	padding: 0;

}
.number {
	font-size: 2.2rem;
	background: url(/img/telephon_signal.png) no-repeat ;
	background-position:  left;
	display: block;
	padding-left: 21px;
	margin-top: -2px;
}
.city {
	margin-top: 3px;
}
.rightbar {
	margin-top: 15px;
}

.cityblock {
	font-size: 12px;
	border-radius: 9px;
	background-color: $green_min;
	color: #fff;
	padding: 3px 0px 3px 15px;
	line-height: 15px;

}
.basket {
	margin-top: 10px;
	border-radius: 12px;
	border: solid 1px #bcbec0;
	background-color: #fff;
	font-size: 12px;
	overflow: hidden;
	p {
		padding: 9px 0px 0px 17px;
	}
}
.basket__bol {
	background: $yyellow url(/img/basket_img.png) no-repeat 17px;
	/*background-position-x: 17px;*/
	padding:  9px 0px 9px 51px;
	font-family: 'Roboto Slab', serif;
	font-size: 1.5rem;
	border-bottom: 1px solid rgba(116,155,118,.5);

}
.search {
	border: solid 1px #bcbec0;
	margin-top: 10px;
	border-radius: 7px;
	overflow: hidden;
	position: relative;
}
.search__only {
	
	/*white-space: nowrap;*/
	input {
		/*width: 50px;*/
		max-width: 80%;
		border: 0px;
		padding-left: 15px;

	}
	input:focus {
		outline: none !important;
	}
	button {
		/*margin-left: -4px;*/
		background-color: rgba(116,155,118,0.6);
		border: 0px;
		/*padding: 2px 10px;*/
		width: 37px;
		height: 24px;
		color: #fff;
		position: absolute;
		.fa {
			position: absolute;
			left: 10px;
			top: 3px;
			/*box-shadow: -2px 1px 1px rgba(0,0,0,0.9)*/
		}
		.fa:nth-child(1) {
			color: #000;
			position: absolute;
			left: 9px;
			top: 4px;

		}
	}
	
}

ol, ul {
    margin-top: 0;
    margin-bottom: 0px;
}


#menu {
	width: 100%;
	background-color: $yyellow;
	text-decoration: none;
	display: block;
	margin-top: 35px;
	box-shadow: 7px 0px 8px rgba(0, 0, 0, 0.5);
}
#menu__main {
	display: block;
	ul {
		padding: 0px;
		display: block;
		li {
			display: block;
			float: left;
			padding: 20px 27px 20px 27px;
			font-size: 2.1rem;
			position: relative;

		}

		li:nth-child(3) {
			padding: 20px 33px 20px 33px;
		}
		li:before {
			content: "толщиномеры";
			font-family: 'Roboto', sans-serif;
			font-size: 1rem;
			text-transform: uppercase;
			position: absolute;
			text-align: center;
			font-weight: 700;
			margin: auto;
			top: -18px; left: 0; bottom: 0; right: 0;
			color: #7D7D7D;
		}

		li:hover::before {
			top: 3px;
			color: #fff;
			transition: 0.5s ease-out;
		}
		
		li:nth-child(n+6)::before {
			content: "";
		}

		li:hover {
			background: rgba(50,109,30,1);
			background: -moz-linear-gradient(top, rgba(50,109,30,1) 0%, rgba(50,109,30,0.6) 100%);
			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(50,109,30,1)), color-stop(100%, rgba(50,109,30,0.6)));
			background: -webkit-linear-gradient(top, rgba(50,109,30,1) 0%, rgba(50,109,30,0.6) 100%);
			background: -o-linear-gradient(top, rgba(50,109,30,1) 0%, rgba(50,109,30,0.6) 100%);
			background: -ms-linear-gradient(top, rgba(50,109,30,1) 0%, rgba(50,109,30,0.6) 100%);
			background: linear-gradient(to bottom, rgba(50,109,30,1) 0%, rgba(50,109,30,0.6) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#326d1e', endColorstr='#326d1e', GradientType=0 );;
			color: #fff;
			

		}
	}
	
}

.menu_templates {
	display: none;
}
#autotest_content, #cm_content, #russia_content, #kitaiwan_content, #ultrasound_content, #faq_content {
	ul {
		padding: 0 0 5px 0;
	}
	li {
		display: block;
		padding: 0 10px;
		cursor: pointer;
	}
	
	li:hover {
		background-color: #E7E7E7;
	}
	li:hover a {
		color: $orang;
	}
	li a {
		color: #000;
		font-size: 1.7rem;
		line-height: 3.2rem;
		font-weight: 700;
		text-decoration: none;
	}
	hr {
		margin: 3px 10px;
	}
	ul li:last-of-type a {
		color: $orang;
		line-height: 2rem;
		font-weight: 400;
	}

}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-shadow-menu {
	box-shadow: 0px 40px 30px -35px rgba(0,0,0,1);
}

/*#tooltipster-440608:hover .tooltipster-box:hover {
	color: #fff;
	background: rgba(50,109,30,1);
			background: -moz-linear-gradient(top, rgba(50,109,30,1) 0%, rgba(50,109,30,0.6) 100%);
			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(50,109,30,1)), color-stop(100%, rgba(50,109,30,0.6)));
			background: -webkit-linear-gradient(top, rgba(50,109,30,1) 0%, rgba(50,109,30,0.6) 100%);
			background: -o-linear-gradient(top, rgba(50,109,30,1) 0%, rgba(50,109,30,0.6) 100%);
			background: -ms-linear-gradient(top, rgba(50,109,30,1) 0%, rgba(50,109,30,0.6) 100%);
			background: linear-gradient(to bottom, rgba(50,109,30,1) 0%, rgba(50,109,30,0.6) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#326d1e', endColorstr='#326d1e', GradientType=0 );;
			color: #fff;
}*/

.mainpage {
	margin-top: 30px;
	height: 100px;
	

}
.catalog {
	max-width: 107px;
	background-color: black;
	color: #fff;
	font-family: 'Roboto Slab', serif;
	height: 26px;
	font-size: 1.7rem;
	padding-left: 14px;
	border-radius: 6px 6px 0px 0px;
	box-shadow: 1px 1px 1.4px rgba(0, 0, 0, 0.3);
	position: relative;

}

.catalog:after {
	content: "";
	height: 1px;
	width: 170%;
	background-color: #000;
	box-shadow: 1px 1px 1.4px rgba(0, 0, 0, 0.3);
	display: block;
	position: absolute;
	left: 0px;
	bottom: 0px;
}
.navtabs {
	margin-top: 20px;
	& > div {
			margin-top: 20px;
			font-size: 1.6rem;
		}
	.nav-tabs_mod {
		border-bottom: 0px;
		font-size: 1.7rem;
		font-family: 'Roboto Slab', serif;
		/*box-shadow: 1px 1px 1.4px rgba(0, 0, 0, 0.3);	*/
	}
	.nav-tabs_mod:after{
		content: "";
		height: 1px;
		background-color: #000;
		box-shadow: 1px 1px 1.4px rgba(0, 0, 0, 0.3);
		display: block;
		/*position: absolute;*/
		/*left: 0px;
		bottom: 0px;*/
	}
	.nav-tabs_mod > li > a {
		padding: 6px 15px;
		color: #000;
	}
	.nav-tabs_mod > li.active > a {
		background-color: black;
		color: #fff;
		/*height: 26px;*/
		border: 1px solid transparent;
		/*padding-left: 14px;*/
		border-radius: 6px 6px 0px 0px;
		box-shadow: 1px 1px 1.4px rgba(0, 0, 0, 0.3);
	}
	.nav-tabs_mod > li > a:focus, .nav-tabs_mod > li > a:hover {
		/*background-color: $green_min;*/
		border: 1px solid transparent;
		border-radius: 6px 6px 0px 0px;
		color: $orang;

	}
	.nav-tabs_mod > li.active > a:focus, .nav-tabs_mod > li.active > a:hover {
		color: #fff;
	}
}

ul.catalog_product {
	padding: 0px;
	
	margin-top: 10px;
	li {
		text-decoration: none;
		display: block;
		i {
			color: $green_max;
			font-size: 1.5rem;
		}
		a {
			color: #000;
			font-size: 1.7rem;
			text-decoration: underline;
		}
		a:hover {
			color: $orang;
			text-decoration: none;
		}
		a:visited {
			/*color: $green_max;*/
		}
	}
}
.catalog__new{
	margin-top: 60px;
}
.catalog__new_bottom {
	display: none;
}
.sidebar__new {
	max-width: 185px;
	margin-top: 30px;
	
	img {
		width: 100%;
		border: solid 2px #D8D8D8;
		box-shadow: 1px 2px 5px -1px rgba(0,0,0,.2);
	}
	.sidebar__text {
		margin-top: 10px;
	}
	.sidebar__link {
		color: $green_max;
		text-align: right;
		margin-bottom: 25px;
		a {
			color: $green_min;
			text-decoration: underline;
		}
		a:hover {
			color: $orang;
			text-decoration: none;
		}
		span {
			font-size: 1.5rem;
		}
	}

}
.sidebar__new_bottom{
	display: none;
}
.mainpage__slider {
	max-height: 375px;
	
	border: solid 1px #000;
	border-radius: 12px;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
	z-index: 1100;
	/*cursor: url(http://findicons.com/files/icons/1840/free_style/128/hand.png) 4 12, auto;*/
	cursor: all-scroll;
	margin-bottom: 37px;
	
	div {
	overflow: hidden;
	max-height: 375px;	
	border-radius: 12px;

/*	.slick-prev::before, .slick-next::before {
		color: #000;
		font-size: 29px;
	}

	i {
		color: #000;
	}*/
	
	
	p {
		position: absolute;
		font-family: 'Roboto Slab', serif;
	}
	
	} 
	
	img {
		width: 100%;
	}
}

.mainpage__slider .slick-track div:nth-child(1)  {
	font-size: 3rem;
	p {
		left: 10px;
		top: 10px;
	}

}
.mainpage__slider .slick-track div:nth-child(2)  {
	font-size: 4.7rem;
	color: #fff;
	p {
		left: 25px;
		top: 20px;
		text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.7);
		span {
			font-size: 5.5rem;
			display: block;
		}
	}
}

.wow {
		visibility: hidden;
	}

.mainpage__slider .slick-track div:nth-child(3)  {
	font-size: 3rem;
	color: #fff;
	text-shadow: 2px 2px 12px rgba(0, 0, 0, 0.3);
	p {
		left: 28px;
		top: 47px;
	}
}


/*----Генератор движения*/

/*.animation-target {
  animation: animation 5000ms linear both;
}*/

/* Generated with Bounce.js. Edit at https://goo.gl/UfvVC3 */

@keyframes animation { 
  0% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 500, 0, 0, 1); }
  6.31% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 308.097, 0, 0, 1); }
  12.51% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 189.467, 0, 0, 1); }
  18.82% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 114.438, 0, 0, 1); }
  25.03% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 68.949, 0, 0, 1); }
  37.54% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 23.97, 0, 0, 1); }
  50.05% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 7.873, 0, 0, 1); }
  75.08% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.635, 0, 0, 1); }
  100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } 
}
/*--------------------------*/

/*
.sport {
	width: 100px;
	height: 100px;
	background-color: #FF6A00;
}
*/

/*.grid_container {
	display: grid;
	grid-template-columns: [first] 50% [last] 50%;
	grid-column-gap: 15px;
	grid-auto-flow: column;
	grid-template-areas: "article_p article_p";
	.columns_container {
		columns: 2;
	}
	
}*/


.mainpage__new {
	border: solid 1px #000;
	border-radius: 12px;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
	padding: 20px;
	

	h2 {
		font-family: 'Roboto Slab', serif;
		font-size: 2.4rem;
		text-transform: uppercase;
		margin-top: 5px;
	}
	.columns_container {
		columns: 2;
		img {
			max-width: 320px;
			margin: 10px 0;
		}
	}
	p {
		font-size: 1.4rem;
		
		/*display: block;*/
		
		

		span {
			font-weight: 700;
			color: $orang;
		}
	}

}

.mainpage__new_posts {
	border: solid 0px;
	box-shadow: none;
	margin-top: 40px;
	h2 {
		margin-bottom: 20px;
	}
}

.mainpage__new_posts2 {
	border: solid 0px;
	box-shadow: none;
	margin-top: 40px;
	h2 {
		margin-bottom: 20px;
	}
	.columns_container {
		columns: 1;
		img {
			max-width: 65%;
			margin: 10px 10px 10px 0;
			float: left;
		}
		& img:nth-of-type(2)  {
			max-width: 30%;
			float: right;
			
		}
		& img:nth-of-type(3)  {
			max-width: 30%;
			float: right;
		}
		p {
			float: left;
		}
	}
}

.mainpage__new_posts2, .mainpage__new_posts3 {
	border: solid 0px;
	box-shadow: none;
	margin-top: 40px;
	h2 {
		margin-bottom: 20px;
	}
	.columns_container {
		columns: 1;
		img {
			max-width: 65%;
			margin: 10px 10px 10px 0;
			float: left;
		}
		& img:nth-of-type(2)  {
			max-width: 30%;
			float: right;
			
		}
		& img:nth-of-type(3)  {
			max-width: 30%;
			float: right;
		}
		p {
			float: left;
		}
	}
}
.mainpage__new_posts3 {
	.columns_container {
		img {
		margin-right: 20px;
		}
	}
	
	blockquote {
		font-family:'Roboto Slab', serif;
		font-size: 1.8rem;
		/*display: block;*/
		margin: 5% 0;
		border-left: 0;
		font-weight: 700;
		color: #8B8B8B;
		&:before {
			content:'50%';
			font-family: 'Roboto', sans-serif;
			font-size: 9.4rem;
			color: $green_max;
			display: block;
			line-height: 9rem;


		}
	}
}

.mainpage_wrapper_post{
	border-left: solid 3px rgba(116,155,118,.7);
	margin-top: -40px;
}

.comparison {
	background: $orang url(/img/fon-tolshinx640_2.jpg) no-repeat center right 80%;
	padding: 13px;
	margin-top: 30px;
	position: relative;
	cursor: pointer;
	.triangler {
		width: 0;
		height: 0;
		border-top: 23px solid transparent;
		
		border-bottom: 23px solid transparent;
		position: absolute;
	}
	.triangler_mod1 {
		top: 50%;
		left: 17px;
		margin: -23px 0 0 0;
		z-index: 5;
		border-left: 23px solid $green_min;
	}
	.triangler_mod2 {
		top: 50%;
		left: 19px;
		margin: -20px 0 0 0;
		z-index: 1;
		border-left: 23px solid #000;

	}

	&:hover .triangler_mod1 {
	border-left: 23px solid #DABD24;
	}


	h2 {
		font-family: 'Roboto Slab', serif;
		font-weight: 700;
		color: #fff;
		font-size: 2.4rem;
		text-transform: uppercase;
		margin-left: 39px;
		margin-right: 130px;
		margin-top: 10px;
		letter-spacing: 0.05rem;
		line-height: 3rem;
		border-bottom: solid 2px;
		border-top: solid 2px; 
		border-color: transparent;
		padding: 5px 0px;
		/*display: block;*/
		
	}

	&:hover h2 {
		color: $yyellow;
		transition: 0.7s ease-out;
		text-shadow: 4px 5px 5px rgba(0,0,0,.7);
		border-color: #fff;
	}
	.comparison__banner01 {
		position: absolute;
		right: 0px;
		top: 50%;
		margin: -85px 0 0 0;
	}
	.comparison__banner02 {
		position: absolute;
		right: 89px;
		top: 50%;
		margin: -37px 0 0 0;
		transform: rotate(-22deg);
	}
	.comparison__banner03 {
		position: absolute;
		right: 35px;
		top: 50%;
		margin: -49px 0 0 0;
		transform: rotate(-12deg);
	}
	.comparison__banner04 {
		position: absolute;
		right: 47px;
		top: 50%;
		margin: -38px 0 0 0;
		transform: scale(0.95);
		
	}
}

.h_product {

    font-family: 'Roboto Slab',serif;
    font-size: 2.4rem;
    text-transform: uppercase;
    margin-top: 40px;
}    

.mainpage__product {
	border: solid 1px $green_max;
	border-radius: 4px;
	box-shadow: 1px 1px 3px rgba(49, 109, 30, 0.55);
	padding: 17px;
	margin-top: 16px;
	text-align: center;
	cursor: e-resize;

	.slick-prev::before, .slick-next::before {
		color: #CCCCCC;
		font-size: 29px;
		background-color: #fff;
		opacity: 1;
		height: 100px;
	}

	.slick-prev {
    	left: -15px;
    	background-size: 5px;
	}

	.slick-next {
    right: -7px;
	}

	.slick-prev:hover:before,
	/*.slick-prev:focus:before,*/
	.slick-next:hover:before
	/*.slick-next:focus:before*/
	{
	   color: $orang;
	}


	.mainpage__cart {
		width: 100%;
		/*min-height: 430px;*/
		margin: 10px;
		
		.img__cart {
			border: solid 1px #000;
			border-radius: 12px;
			width: 148px;
			height: 176px;
			overflow: hidden;
			display: block;
			margin: 0 auto 10px auto;
			cursor: pointer;
			img {
				margin: 0 auto;
				height: 100%;
			}
		}

		.img__cart:hover {
			box-shadow: 2px 2px 7px rgba(0,0,0,.5);

		}

		.text__cart {
			line-height: 1.7rem;

			b {
				margin: 4px 0;
				display: block;
			}
		}

		.sale__cart {
			font-size: 2.5rem;
			color: $green_max;
			font-family: 'Roboto', sans-serif;
			font-weight: 700;
			display: block;
			text-align: center;
			span {
				font-size: 2rem;

			}
		}

		.basket__cart {
			border: solid 1px #000;
			border-radius: 6px;
			background-color: #878787;
			padding: 3px 21px;
			color: #fff;
			display: inline-block;
			&:hover {
				background-color: $orang;
			}
		}
	}
	.mainpage__cart:focus {
		outline: none;
	}
}

.magnifier__img {
	height: 375px;
	width: 375px; 
	border: solid 1px #000;
	border-radius: 12px;
	box-shadow: 1px 1px 3px rgba(0,0,0,.5);
	/*cursor: all-scroll;*/
	margin-bottom: 33px;
	overflow: hidden !important;
	
		.zoomWrapper {
			/*z-index: -10;*/
			width: inherit !important;
			height: inherit !important;
			border-radius: 12px;
			img {
			width: 373px !important;
			height: 373px !important;
			border-radius: 12px;
		}
	}	
}
/*.zoomWrapper {
	height: 375px !important;
	width: 375px !important; 
	img#zoom_mw {
			width: 370px;
		}
}*/
.magnifier {
	margin-top: 8px;
}
.product__wrapp2 {
	margin-top: -325px;
}
.magnifier__thumbs {
	/*z-index: 2000;*/
	a img{
		/*z-index: -10;*/
	width: 66px;
	height: 66px;
	border: 1px solid #E0E0E0;
	float: left;
	margin-right: 3px;
	}
}
.zoomContainer {
	.zoomWindow {
		border-radius: 5px;
		width: 375px !important;
		height: 375px !important;
	}
	width: 375px !important;
	height: 375px !important;
}

.product__block {
	font-family: 'Roboto Slab', serif;
	h1 {
		font-size: 3.3rem;
		color: $green_max;
		margin: 0;
	}
	h2 {
		font-size: 2rem;
		margin: 7px 0 20px;
	}
}
.product__firm {
	margin-bottom: 20px;
}
.product__checkbox {
	label + span {
		font-weight: 700;
	}
	.radio {
	  position: absolute;
	  z-index: -1;
	  opacity: 0;
	  margin: 10px 0 0 7px;
	}
	.radio + label {
	  position: relative;
	  padding: 0 0 0 27px;
	  cursor: pointer;
	}
	.radio + label:before {
	  content: '';
	  position: absolute;
	  top: 1px;
	  left: 0;
	  width: 17.5px;
	  height: 17.5px;
	  border: 1px solid #989898;
	  border-radius: 50%;
	  box-shadow: inset 0 0 5px 1px rgba(0,0,0,.2);
	}
	.radio + label:after {
	  content: '';
	  position: absolute;
	  top: 5.5px;
	  left: 4.5px;
	  width: 8px;
	  height: 8px;
	  border-radius: 50%;
	  background: $green_max;
	  /*box-shadow: inset 0 1px 1px rgba(0,0,0,.5);*/
	  opacity: 0;
	  transition: .2s;
	}
	.radio:checked + label:after {
		opacity: 1;
	}
	.radio:focus + label:before {
	  box-shadow: inset 0 0 5px 1px rgba(0,0,0,.2), 0 0 0 2px rgba(255,255,0,.7);
	}
}
.product__sale {
	font-size: 4.1rem;
	color: $orang;
	margin: 10px 0;
	span {
		font-size: 3rem;
		color: #000;
	}
}
.product__batton {
	border: 1px solid $green_max;
	padding: 3px;
	border-radius: 7px;
	width: 300px;
	position: relative;
	height: 68px;
	
	.product__basket {
		position: absolute; 
		top:3px;
		bottom:3px;
		width: 58%;
		height: auto;
		background-color: $green_max;
		font-size: 2.3rem;
		border-radius: 8px;
		float: left;
		padding-top: 5px;
		color: #fff;
		img {
			margin-top: -5px;
		}

	}

	.product__click {
		position: absolute; 
		top:3px;
		bottom:3px;
		right: 3px;
		width: 39%;
		color: #fff;
		background: rgba(216,65,0,1);
		background: -moz-linear-gradient(top, rgba(216,65,0,1) 0%, rgba(216,65,0,1) 22%, rgba(166,64,0,1) 93%, rgba(166,64,0,1) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(216,65,0,1)), color-stop(22%, rgba(216,65,0,1)), color-stop(93%, rgba(166,64,0,1)), color-stop(100%, rgba(166,64,0,1)));
		background: -webkit-linear-gradient(top, rgba(216,65,0,1) 0%, rgba(216,65,0,1) 22%, rgba(166,64,0,1) 93%, rgba(166,64,0,1) 100%);
		background: -o-linear-gradient(top, rgba(216,65,0,1) 0%, rgba(216,65,0,1) 22%, rgba(166,64,0,1) 93%, rgba(166,64,0,1) 100%);
		background: -ms-linear-gradient(top, rgba(216,65,0,1) 0%, rgba(216,65,0,1) 22%, rgba(166,64,0,1) 93%, rgba(166,64,0,1) 100%);
		background: linear-gradient(to bottom, rgba(216,65,0,1) 0%, rgba(216,65,0,1) 22%, rgba(166,64,0,1) 93%, rgba(166,64,0,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d84100', endColorstr='#a64000', GradientType=0 );
		font-size: 1.7rem;
		border-radius: 8px;
		padding-top: 3px;
	}

}
.product__table {
	font-family: 'Roboto Slab', serif;
	text-transform: uppercase;
	margin-top: 40px;
	width: 320px;
	&:hover a {
		text-decoration: underline;
	}
	i {
		font-size: 2.2rem;
		color: $green_max;
	}
	a {
	 color: #000;
	}
}

.sweep-to-right {
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: color 1000ms;
    transition: color 1000ms;
}
.sweep-to-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    border: 1px solid #000;
    background: $green_maxmax;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition: 300ms ease-out;
    transition: 300ms ease-out;
}
.sweep-to-right:hover:before {
	border-radius: 8px;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.product__advantages {
	padding: 0;
	margin-top: 30px;
	font-size: 1.5rem;
	li {
		display: block;
		margin: 20px 0;
		p {
			padding-top: 4px;
		}
	}
	div {
		width: 50px;
		height: 50px;
		float: left;
		margin: 0 25px 0 0;
		background-color: rgba(49,109,30,.2);
		img {
			width: 100%;
		}
	}
}

footer {
	min-height: 200px;
	width: 100%;
	margin-top: 50px;
	margin-bottom: 0px;
	color: #fff; 	
	background-color: rgb(28,50,30);

	.footer__col {
		float: left;
		margin: 20px 50px;
		h6 {
			text-transform: uppercase;
		}
		ul {
			padding-left: 0;
			li {
			display: block;
			margin: 5px 0;
			font-size: 1.3rem;
			a {
				color: #fff;
			}
			}
		}
		
	}
	.footer__col_wrap{
		min-height: 160px;
		border-bottom:  1px solid #fff ;
		position: relative;
	}
	.footer__copyright {
		font-size: 1.1rem;
		padding: 0;
		position: absolute;
		bottom: -45px;
	}
	.footer__copyright_2 {
		display: none;
		font-size: 1.1rem;
		padding: 0;
		position: absolute;
		bottom: -45px;
		text-align: right;
	}
	.footer__tel {
		margin: 20px 0px;
		position: relative;
		padding: 0;
		float: left;
		img {
			position: absolute;
			left: -10px;
			top: 15px;
		}
		.city {
			text-align: right;
		}
		.footer__number {
			font-size: 2.2rem;
			display: block;
			margin-top: -2px;
			text-align: right;
			}
		hr {
			visibility: hidden;
			margin: 5px 0;
		}
	}
	
}
.footer__col:nth-child(1)  {
		margin-left: 0;
	}		